.welcomeCardContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

/* .welcomeCardContainerBar::after {
    content: '';
    width: 100%;
    height: 3px;
    background-color: #000000;
    margin: 1rem 0;
} */

.welcomeCardButtons {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 0.2rem;
}


@media (max-width: 600px) {
    /* .welcomeCardWrapper {
        margin-top: 2vh; 
    } */
    .welcomeCardContainer {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }
    .welcomeCardButtons {
        align-items: flex-start;
    }
}